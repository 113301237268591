@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;900&display=swap");

:root {
  --primary-color: #175ba4;
  --secondary-color: #175ba4;
  --tertiary-color: #175ba4;
  --success-color: #388117;
  --error-color: #C11717;
  --warning-color: #EF8E14;
  --print-border-color: #4E0114;
}

.tertiary-color-text {
  color: var(--tertiary-color) !important;
 }

.primary-color-text {
 color: var(--primary-color) !important;
}

.primary-color-background {
  background-color: var(--primary-color) !important;
 }

.primaryImg {
  color: var(--primary-color) !important;
}

body {
  font-family: "Poppins", sans-serif !important;
}
/* for removing arrows from input number field */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
/*secondary headings in form and pages */
.detailsHeading {
  text-align: left;
  width: 175px;
  font: normal normal normal 24px/35px Poppins;
  letter-spacing: 0px;
  margin: 1rem 0;
  color: #101010;
  opacity: 1;
}
/* border of custom select field */
.selectBorder,
.inputFields {
  border: none;
}

/*padding css*/
.pb-6 {
  padding: 6px !important;
}
.p-8 {
  padding: 8px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.p-10 {
  padding: 10px !important;
}
.p-12 {
  padding: 12px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.p-28-100 {
  padding: 1.75em 6.25em !important;
}

.m-24-100 {
  margin: 24px 100px !important;
}
.m-0-100 {
  margin: 0 100px !important;
}

/*padding css*/
.p-8 {
  padding: 8px !important;
}
.p-15 {
  padding: 15px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-0-10 {
  padding: 0 10px !important;
}
/*margin css*/
.m-10 {
  margin: 10px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-6 {
  margin: 6px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-4 {
  margin-left: 4px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-8 {
  margin-right: 8px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}
.mr-24 {
  margin-right: 24px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}
.mr-10 {
  margin-right: 10px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}
.mb-13 {
  margin-bottom: 13px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-2 {
  margin-bottom: 2px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt--20 {
  margin-top: -20px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-48 {
  margin-top: 48px !important;
}
/*text css*/

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-14 {
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}

.font-weight-400 {
  font-weight: 400 !important;
  font-family: "Poppins" !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-primary-2 {
  color: #0086e5 !important;
}

.text-secondary-1 {
  color: #6B6C6F;
}

.text-success {
  color: #3fba07 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-start {
  text-align: start !important;
}

.text-align-left-no-width {
  text-align: left !important;
}

.align-self-center {
  align-self: center !important;
}

/*position css*/

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

/*flex css*/

.flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-center {
  justify-content: center !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-middle {
  align-items: center !important;
}
.flex-spaceBetween {
  justify-content: space-between !important;
}
.flex-spaceAround {
  justify-content: space-around !important;
}
.flex-spaceEvenly {
  justify-content: space-evenly !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-6 {
  gap: 6px !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.align-flex-start {
  align-items: flex-start !important;
}
.align-flex-end {
  align-items: flex-end !important;
}

/*bg color*/

.bg-light-grey {
  background-color: #cdcdcd !important;
}

.bg-white {
  background-color: white !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-start {
  justify-content: flex-start !important;
}
.flex-column {
  flex-direction: column;
}

/* antd button  */
.button {
  background-color: var(--primary-color) !important;
  border-radius: 5px !important;
  color: #fff !important;
  border: none;
  border: 1px solid var(--primary-color) !important;
}

.button-white {
  background-color: white !important;
  border-radius: 5px !important;
  color: var(--primary-color) !important;
  border: none;
  border: 1px solid var(--primary-color) !important;
}

.button:disabled {
  border: 1px solid #d9d9d9 !important;
  background-color: #cdcdcd !important;
  color: #fff !important;
  border: none;
}
/* margin bottom  */
.mb-5 {
  margin-bottom: 5px !important;
}
/* form label header */
.inputFieldHeader {
  text-align: left;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #101010;
  opacity: 1;
  margin-bottom: 0.2rem;
}

.siteLogoSidebar {
  /* background: url("./assets/images/__SQ_Logo_Reverse_Strapline.png") no-repeat; */
  background-size: 100%;
  width: 180px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}

.topbar-logo .siteLogoSidebar {
  background-position: center;
}

@media only screen and (max-width: 767px) {
  .siteLogoSidebar {
    background-position: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

.siteCollapsedLogoSidebar {
  /* background: url("./assets/images/__SQ_Icon_Gradient.png") no-repeat; */
  background-size: 100%;
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
}

.opacity-0 {
  opacity: 0.0 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

/*height*/
.h-80 {
  height: 80vh !important;
}

.h-90 {
  height: 90vh !important;
}

.h-100per {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.h-fit-content {
  height: fit-content !important;
}

/*others*/

.color-red {
  color: red;
}

.input-error-helper-text {
  color: red;
  font-size: 13px;
}

.input-field-error .ant-select-selector,
.input-field-error .ant-input,
.input-field-error-picker {
  border: 1px solid red !important;
}
/*bg color*/

.bg-light-grey {
  background-color: #cdcdcd !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.center-relative-element {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 6px;
}
.align-center {
  width: 100% !important;
  text-align: center !important;
}

.text-align-left {
  width: 100% !important;
  text-align: left !important;
}
.error {
  color: rgb(201, 1, 1);
}
.display-none {
  display: none !important;
}
.black {
  color: #111 !important;
}
.ant-input-disabled {
  background: hsl(0, 0%, 93%) !important;
  color: black !important;
}
.css-1hdwq5x-control,
.ant-input-affix-wrapper-disabled,
.ant-picker-disabled {
  background: hsl(0, 0%, 93%) !important;
  color: black !important;
}

.ant-picker-input input {
  color: black !important;
}

.ant-radio-button-wrapper-checked {
  background-color: var(--tertiary-color) !important;
  border: 1px solid var(--tertiary-color) !important;
}

.ant-radio-button-wrapper:hover {
  color: none !important;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
color: var(--tertiary-color);
}

.css-107lb6w-singleValue {
  color: black !important;
}
.css-qlet36-control {
  background: hsl(0, 0%, 93%) !important;
}
.height_100 {
  height: 100% !important;
}

.invert-image {
  filter: invert(100%);
}
.top-10 {
  top: 10px !important;
}
.imgElement-app {
  height: 130px;
  width: 330px;
}

.playLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.m-2rem {
  margin: 2rem !important;
}

.headerTitle {
  font-weight: 400 !important;
  color: var(--primary-color) !important;
}

.underline {
  text-decoration: underline !important;
}

.w-60px {
  width: 60px !important;
}

.w-185 {
  width: 185px !important;
}

.w-200 {
  width: 200px !important;
}

.w-max-content {
  max-width: max-content !important;
}

.w-min-content {
  max-width: min-content !important;
}

.w-fit-content {
  max-width: fit-content !important;
}

.w-300 {
  width: 300px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.icon-color-1 {
  color: #636363b5;
}

.text-black-on-hover:hover {
  color: black !important;
}

.text-black {
  color: black !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.filterModal .ant-modal-header {
  display: none !important;
}

.filterModal .ant-modal-footer {
  display: flex !important;
  border-top: none !important;
  justify-content: space-between;
  padding: 0 24px 20px 24px !important;
  border-radius: 5px;
}

.filterModal .ant-modal-body {
  padding: 10px 24px 20px 24px !important;
  border-radius: 5px;
}

.filterModal .ant-modal-close {
  display: none !important;
}

.filterModal > p,
.dateInput > p {
  font-size: 15px !important;
  opacity: 0.9;
  letter-spacing: 0.2px;
  text-align: left !important;
}

.filterModal .ant-modal-body {
  text-align: left !important;
}

.filterModal .ant-btn {
  width: 100px !important;
  height: 39px !important;
}

.filterModal .ant-modal-content {
  border-radius: 6px !important;
}

.filterModal .ant-select-selector {
  padding: 5px 15px 5px !important;
  border-radius: 5px !important;
  height: 40px !important;
}

.filterModal .ant-select-selector input {
  margin-top: 4px !important;
}

.filterModal .ant-picker {
  padding: 5px 9px 5px !important;
  border-radius: 5px !important;
  height: 40px !important;
}

.filterModal p {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
}

.antVerticalTableBorder .ant-table-tbody > tr > td {
  border: none !important;
}

.antVerticalTableBorder .ant-table-tbody > tr > td:not(:first-child) {
  border-left: 1px solid #f0f0f0 !important;
}

.antVerticalTableBorder .ant-table-thead > tr > th:not(:first-child) {
  border-left: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.w-150 {
  width: 150px !important;
}

.float-right {
  float: right !important;
}

.greenEye {
  background-color: #ffcba8 !important;
  color: black !important;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.fyi {
  background-color: #a4cdf1 !important;
  color: black !important;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.italic {
  font-style: italic !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.dropdown-disable-color-1 .ant-select-disabled .ant-select-selector {
  background-color: hsl(0, 0%, 93%) !important;
  color: black !important;
}

.overflow-auto {
  overflow: auto !important;
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.white-space-normal {
  white-space: normal !important;
}

.popover-p-0 .ant-popover-inner-content {
  padding: 0px !important;
}

.second-line-truncate {
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.multi-line-truncate {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.one-line-truncate {
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-wrap: break-word;
}

.ant-notification  {
  top: 78px !important;
}

.custom-antd-notification {
  background-color: #999999 !important;
  color: #fff !important;
  border-radius: 15px 0px 0px 15px !important;
  right: -24px;
  padding: 16px !important;
  opacity: 0.93 !important;
}

.ant-notification-notice-message {
  font-size: 14px !important;
  color: #fff !important;
}

.ant-notification-notice-message .title {
  font-weight: 600 !important;
}

.mnw6qvm {
  overflow: hidden;
  overflow-y: auto !important;
  max-height: 250px;
}

.custom-recharts-tooltip {
  background-color: #fff !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  padding: 10px !important;
  color: #000 !important;
}

.execute_incident-downloadPdfRadioBtn .ant-radio-wrapper {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.execute_incident-downloadPdfRadioBtn .ant-checkbox-wrapper {
  margin-bottom: 5px !important;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	border-radius: 5px !important;
	background-color: #F5F5F5 !important;
}

::-webkit-scrollbar {
	width: 5px !important;
  height: 6px !important;
	background-color: #F5F5F5 !important;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px !important;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
	background-color: grey !important;
}

#root, .ant-popover, .ant-select-dropdown {
  zoom: 100%;
}

/* @media screen and (max-width: 1380px) {
  #root, .ant-popover, .ant-select-dropdown {
    zoom: 80%;
  }

  .ant-popover,
  .ant-select-dropdown {
    zoom: 80% !important;
  }
} */

.display-block {
  display: block !important;
}

.global-red-asterisk {
  color: #FD0000 !important;
}

@page {
  size: 21cm 940px;
  margin: 0;
}

.tab-wrapper .ant-radio-button-wrapper {
  min-height: 40px;
  height: auto !important;
  padding: 0;
  text-align: center;
}

.hide-cross-react-select .css-xb97g8 {
  display: none !important;
}

.ant-table table {
  margin-bottom: 10px;
}

.ant-table-content:hover::-webkit-scrollbar {
	height: 12px !important;
}

.highlightLink{
  color: var(--primary-color);
}

.map-wrapper {
  --blue: var(--primary-color);
}

#map {
  --height: 70vh;
  height: var(--height);
  width: 100%;
  margin-top: 16px;
}

.map-container {
  height: var(--height);
}

#search-control {
  margin: "10px 0 0 10px";
  left: "80px" !important;
}

.what3words-autosuggest-input-wrapper > input:nth-child(1) {
  width: 300px;
}

.my_action-collapse .ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}

.repeatThisSectionBtn, .repeatThisSectionBtn:hover, .repeatThisSectionBtn:active, .repeatThisSectionBtn:focus {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  background-color: transparent !important;
  margin-right: 4px;
  width: 250px !important;
  height: 40px !important;
}

.fileImage {
  height: 100px;
}

.ant-menu-item {
  padding: 0 26px !important;
}

.capitalize {
  text-transform: capitalize;
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-8 {
  gap: 8px;
}

.flex-gap-12 {
  gap: 12px;
}

.flex-gap-24 {
  gap: 24px;
}

.flex-gap-48 {
  gap: 48px;
}

.ant-modal-no-padding .ant-modal-body {
  padding: 0px !important;
}

.blur-2 {
  filter: blur(2px);
}

.execution-dates {
  font-weight: 500;
}

.printable-table {
  border-collapse: collapse;
  max-width: 100%;
  min-width: 50%;
  border: 1px solid var(--print-border-color);
  border-radius: 10px;
}

.printable-table td, .printable-table th {
  padding: 8px;
  word-wrap: break-word;
  white-space: break-spaces;
}

.printable-table tr {
  min-height: 20px;
}

@media screen and (max-width: 767px) {
  .intercom-lightweight-app {
    display: none;
  }

  .font-size-25 {
    font-size: 16px !important;
  }

  .font-size-28 {
    font-size: 22px !important;
  }

  .mobile-header-title {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 30px;
    padding: 10px 15px;
  }

  .mobile-header-title .headerTitle {
    font-size: 16px I !important;
    font-weight: 400 !important;
    color: #000000 !important;
  }

  .execution-dates {
    font-weight: 400;
    font-size: 13px;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }

  .non-printable, .no-print {
    display: block !important;
  }

  .display-on-print {
    display: none !important;
  }
}

@media print {
  .on_print-hide-element {
    display: none !important;
  }

  .display-on-print {
    display: block !important;
  }

  .on_print-pagebreak {
    page-break-before: always;
  }

  .on_print-page-break-after {
    page-break-after: always;
  }

  .on_print-page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .print_landscape {
    -ms-transform: rotate(270deg);
    /* IE 9 */
    -webkit-transform: rotate(270deg);
    /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    top: 1.5in;
    left: -1in;
  }

  @page {
    /* size: auto; */
    margin: 15px 0 15px 0;
  }
  .avoidBreakInside {
      page-break-inside: avoid;
  }
  .print-m-28 {
    margin: 28px !important;
  }
}

.sq-custom-select-dropdown-value {
  display: flex;
  align-items: center;
}
.pointer {
  cursor: pointer;
}

.pointer-default {
  cursor: default !important;
}

.ant-menu-item-selected a{
  color: var(--secondary-color) !important;
}

.ant-menu-item-selected svg{
  color: var(--secondary-color) !important;
}

.ant-menu-item-selected span{
  color: var(--secondary-color) !important;
}

.linkText {
  color: #1990FF !important;
}



.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.ant-checkbox-checked:hover {
  border-color: var(--primary-color) !important;
  /* box-shadow: 0px 0px 3px var(--primary-color) !important; */
  outline: var(--primary-color) !important;
}

.ant-checkbox-checked:focus {
  border-color: var(--primary-color) !important;
}

.ant-input:hover {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
}
.ant-input:focus {
  border-color: var(--primary-color) !important; 
  box-shadow: 0px 0px 3px var(--primary-color) !important;
}

.addFolder--Btn {
  border-color: var(--primary-color) !important;
  color: white !important; 
}

.addLevel--Btn {
  border-color: white !important;
  color: var(--primary-color) !important; 
}

.ant-btn:hover {
border-color: var(--primary-color) !important;
}

.ant-btn {
  border-color: var(--primary-color) !important;
}

.link-btn {
  color: #1990FF !important;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.ant-select-selector-text-middle .ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-select-selector:hover {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
}

.ant-select-focused .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
}

.ant-select-item-option-selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.ant-select-selection-search-input:focus {
  border: none !important;
}

.ant-btn-background-ghost {
  color: var(--primary-color) !important;
}

.ant-pagination-item-active {
  border-color: var(--tertiary-color) !important;
}

.ant-pagination-item-active a {
  color: var(--tertiary-color) !important;
}

.ant-table-column-sorter-down.active {
  color: var(--tertiary-color) !important;
}

.ant-table-column-sorter-up.active {
  color: var(--tertiary-color) !important;
}

.css-m3ki1x-option {
  background-color: var(--primary-color) !important;
}

/* .css-2uw1in-control {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
} */

.css-w83gjn-option {
  background-color: white !important;
}

/* .css-1vrj3hb-control {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
} */

.ant-picker-today-btn {
  color: var(--tertiary-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--tertiary-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before  {
  border: 1px solid var(--tertiary-color) !important;
} 

.ag-row-hover {
  background-color: rgb(230, 228, 228) !important;
}

.ant-picker-focused {
  border-color: var(--tertiary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
}

.ant-switch-checked  {
  background-color: var(--tertiary-color) !important;
}

.ant-picker:hover {
  border-color: var(--tertiary-color) !important;
}

.ant-picker-input {
  border: none !important;
}

.ant-picker-input:focus {
  border: none !important;
}

.ant-spin-dot-item {
  background-color: var(--tertiary-color) !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: white !important;
}

.filterModalWhiteBtn {
  color: var(--primary-color);
  font-size: 13px;
  font-weight: 400;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterModalWhiteBtn:hover {
  color: var(--primary-color);
}

/* .css-b8ldur-Input:focus {
  border: none !important;
}

input[id^=react-select-] {
  border: none !important;
} */

input[type="radio"]:checked {
  accent-color: var(--tertiary-color) !important;
}

/* Custom CSS for Ant Design radio button to change selection color */
.ant-radio-inner {
  border-color: var(--tertiary-color) !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--tertiary-color) !important;
}

.ant-radio-inner::after {
  background-color: var(--tertiary-color) !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--tertiary-color) !important;
}

/* Change radio button color when checked */
.ant-radio-primary .ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color) !important;
}

.ant-radio-primary .ant-radio-inner:after {
  background-color: var(--primary-color) !important;
}

/* Change the radio button hover color */
.ant-radio-primary .ant-radio:hover .ant-radio-inner {
  border-color: var(--primary-color) !important;
}

.no-border-btn {
  border: none !important;
  color: var(--primary-color) !important;
  box-shadow: 0 0px 1px var(--primary-color) !important;
}

.no-border-btn:hover {
  color: var(--primary-color) !important;
}

.input-border:focus {
  border-color: var(--tertiary-color) !important;
  outline: none;
}
 
/* Ant Button Remove Wave Animation On Click */
.ant-btn {
  &::after {
      all: unset;
  }
}

.borderNoneBtn {
  border: none !important;
  border-color: transparent !important;
}

.exitBtn {
  min-width: 250px !important;
  max-width: 250px !important;
  height: auto !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.ant-picker-now-btn{
 color: var(--primary-color) !important;
}

.ant-btn-primary {
  background: var(--primary-color) !important;
}

.ant-btn-primary[disabled] {
  color: white !important;
}

.ant-input-disabled {
  background: hsl(0, 0%, 95%) !important;
}

.ant-picker-disabled {
  background: hsl(0, 0%, 95%) !important;
}

.tenant-logo {
  max-width: 200px;
  max-height: 40px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: 8px;
}

.tenant-logo-task {
  max-width: 200px;
  max-height: 40px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: 24px;
  margin-bottom: 8px;
}

.customAntTable .ant-table-container table th {
  background-color: white !important;
  color: #636363 !important;
}

.customAntTable .ant-table-container table td {
  background-color: white !important;
}

.priority-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid #70707033;
  width: 124px;
  height: 50px;
  cursor: pointer;
  line-height: 18px;
}

.priority-disabled {
  pointer-events: none !important;
  opacity: 1 !important;
}

.custom-ant-checkbox .ant-checkbox-inner {
  border-color: #707070 !important;
  border-radius: 5px !important;
  background-color: transparent; /* No fill color when unchecked */
}

.custom-ant-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.custom-ant-checkbox .ant-checkbox-inner::after {
  opacity: 0 !important; /* Ensure tick is hidden */
}

.custom-ant-checkbox .ant-checkbox-checked::after {
  border: none !important;
}

.ant-tabs-ink-bar {
  background-color: var(--primary-color) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color) !important;
}

.ant-tabs-tab:hover {
  color: var(--primary-color) !important;
}


.custom-select-border-radius .ant-select-selector {
  border-radius: 5px !important;
}

.disabled-select .ant-select-selection-item {
  color: black !important; /* Ensures black text for selected value */
}

.white-space-pre-line {
  white-space: pre-line !important;
}

.draftjs-placeholder-text .public-DraftEditorPlaceholder-inner {
  color: #bfbfbf; 
}

.expand-all-button{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
  border: none !important;
  background-color: #e8e8e8 !important;
  color: #707070 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}

/* .mainDivRA {
  overflow-y: auto;
  max-height: 100vh;
} */

.instructionDiv {
  background-color: #fff;
  /* padding: 10px 0 10px 25px; */
  /* border: 1px solid #e3e0e066; */
  margin-bottom: 12px;
  width: 96% !important;
  overflow: hidden;
}

.mainInstructionDiv {
  background-color: #fff;
  padding: 10px 0 10px 25px;
  border: 1px solid #7e7a7a66;
  border-radius: 4px;
  margin-bottom: 12px;
  width: 100% !important;
  overflow: hidden;
  display: flex;
  width: 100%;
}
.mediaDeleteImage {
  position: absolute;
  bottom: 4px;
  right: 4px; 
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  padding: 1px 2px;
  cursor: pointer;
  z-index: 1; 
}
